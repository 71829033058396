import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { AuthConstants } from '../config/auth-constants';
import { map, switchMap, tap } from 'rxjs/operators';
import { formatDate } from '@angular/common';


@Injectable({
providedIn: 'root'
})
export class AuthService {
userData$ = new BehaviorSubject<any>('');
constructor(
private http: HttpClient,
private storageService: StorageService,
private router: Router
) {}

getUserData() {
  this.storageService.get(AuthConstants.AUTH).then(res => {
  this.userData$.next(res);
  });
  }

login(username, password, webUrl): Observable<any> {
return this.http.post(`${webUrl}/wp-json/jwt-auth/v1/token`, {username, password});
}

getForms(user) {
  console.log(user);
  return this.http.get<any[]>(`${user.web_url}/wp-json/cf-api/v2/forms`).pipe(
    map(data => {
      console.log('forms: ', data) 
      return data;
    })
  );
}

getLeads(date, date14, user, formID) {
  let date1 = new Date(date);
  date1.setDate(date1.getDate() + 1);
  date = formatDate(date1,'yyyy-MM-ddTHH:mm:ss','en-ZA');
  
  date14 = formatDate(date14,'yyyy-MM-ddTHH:mm:ss','en-ZA');
  return this.http.get<any[]>(`${user.web_url}/wp-json/wp/v2/lead?meta_key=hb_form&meta_value=${formID}&after=${date14}&before=${date}`).pipe(
    map(data => {
      console.log('forms: ', data) 
      return data;
    })
  );
}
getCurrentUser() {
  return this.userData$.asObservable();
}

getUserValue() {
  return this.userData$.getValue();
}

logout() {
this.storageService.removeStorageItem(AuthConstants.AUTH).then(res => {
this.userData$.next('');
this.router.navigate(['']);
});
}
}