import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
 
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    user: any;

    constructor(private auth: AuthService) { }
 
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.auth.userData$.subscribe((res:any) => {
            this.user = res;
          })
        
        if (this.user && this.user.token) {
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${this.user.token}`,
                    'Content-type': 'application/json'
                }
            });
        }
 
        return next.handle(request);
    }
}